import Cookies from "js-cookie";
import posthog from 'posthog-js'
import { Elm } from "./Main.elm";
import logoUrl from "./logo.svg";

if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
  posthog.init('phc_yCs973TvnhSeymdHavmjL9cNR0LFo7VoblFVdFxPfl7', { api_host: "https://us.posthog.com" })
}

const tokenKey = "token";
const rememberKey = "remember";

const yes = "yes";

function getToken() {
  if (
    localStorage.getItem(rememberKey) === yes ||
    Cookies.get(rememberKey) === yes
  ) {
    return localStorage.getItem(tokenKey);
  }
  return null;
}

const app = Elm.Main.init({
  node: document.querySelector("main"),
  flags: {
    logoUrl,
    now: Date.now(),
    maybeToken: getToken(),
  },
});

//port postHogCapture : String -> Cmd msg
app.ports.postHogCapture.subscribe((event) => {
  window.requestAnimationFrame(() => {
    posthog.capture(event);
  })
})

//port setToken : Maybe String -> Cmd msg
app.ports.setToken.subscribe((token) => {
  if (token !== null) {
    localStorage.setItem(tokenKey, token);
  } else {
    localStorage.removeItem(tokenKey);
  }
});

//port setRememberToken : Bool -> Cmd msg
app.ports.setRememberToken.subscribe((remember) => {
  if (remember) {
    localStorage.setItem(rememberKey, yes);
    Cookies.remove(rememberKey);
  } else {
    localStorage.removeItem(rememberKey);
    Cookies.set(rememberKey, yes);
  }
});

app.ports.executeReCaptcha.subscribe(() => {
  grecaptcha.ready(() => {
    grecaptcha
      .execute("6LfxVOYkAAAAAHRPNhvi2k4krqwoiy8rko45oD1g", { action: "submit" })
      .then(app.ports.gotReCaptchaToken.send);
  });
});

/*
 * DragDrop fix for Firefox
 */
document.addEventListener(
  "dragstart",
  (event) => {
    event.dataTransfer.setData("text/plain", "");
  },
  false
);
