module Main exposing (main)

import Api exposing (GraphqlResult)
import Browser
import Browser.Navigation as Nav
import ClayRank.Query as Query
import Html exposing (..)
import Html.Attributes exposing (..)
import Html.Events exposing (..)
import Maybe.Extra
import Notification as Notif exposing (Notification)
import Pages.ContactUs as ContactUs
import Pages.Home as Home
import Pages.PrivacyPolicy as PrivacyPolicy
import Pages.Profile as Profile
import Pages.Support as Support
import Pages.Tournaments as Tournaments
import Ports
import Route
import Session exposing (Session(..))
import Taco exposing (Flags, Taco, TacoUpdate(..), avatar, initTaco, recursiveUpdateTaco, updateTaco)
import Time
import Toasty
import Url


main : Program Flags Model Msg
main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        , onUrlRequest = LinkClicked
        , onUrlChange = UrlChanged
        }



-- MODEL


type alias Model =
    { notifications : Notif.Stack
    , taco : Taco
    , showUserMenu : Bool
    , page : Page
    }


type Page
    = HomePage
    | ProfilePage Profile.Model
    | TournamentsPage Tournaments.Model
    | SupportPage Support.Model
    | ContactUsPage ContactUs.Model
    | PrivacyPolicyPage
    | FakeNotAuthorized
    | RefreshAccountLink


init : Flags -> Url.Url -> Nav.Key -> ( Model, Cmd Msg )
init flags url navKey =
    let
        ( taco, tacoCmd ) =
            initTaco flags navKey

        ( model, cmd ) =
            setPage url
                { notifications = Notif.init
                , taco = taco
                , showUserMenu = False
                , page = HomePage
                }
    in
    ( model, Cmd.batch [ Cmd.map UpdateTaco tacoCmd, cmd ] )


setPage : Url.Url -> Model -> ( Model, Cmd Msg )
setPage url model =
    let
        newModel =
            { model | showUserMenu = False }
    in
    case Route.fromUrl url of
        Just Route.Home ->
            ( { newModel | page = HomePage }, Cmd.none )

        Just (Route.Profile clubMode) ->
            let
                ( subModel, subCmd, tacoUpdates ) =
                    Profile.init newModel.taco url clubMode

                ( newTaco, tacoCmd, notifs ) =
                    recursiveUpdateTaco tacoUpdates newModel.taco
            in
            ( { newModel
                | page = ProfilePage subModel
                , taco = newTaco
              }
            , Cmd.batch
                [ Cmd.map ProfileMsg subCmd
                , Cmd.map UpdateTaco tacoCmd
                ]
            )
                |> Notif.addMany NotificationMsg notifs

        Just (Route.Tournaments category tournamentsMode) ->
            let
                ( subModel, subCmd ) =
                    Tournaments.init newModel.taco
                        category
                        tournamentsMode
            in
            ( { newModel | page = TournamentsPage subModel }
            , Cmd.map TournamentsMsg subCmd
            )

        Just (Route.Support _) ->
            ( { newModel | page = SupportPage Support.init }, Cmd.none )

        Just Route.ContactUs ->
            ( { newModel | page = ContactUsPage ContactUs.init }, Cmd.none )

        Just Route.PrivacyPolicy ->
            ( { newModel | page = PrivacyPolicyPage }, Cmd.none )

        Just Route.FakeNotAuthorized ->
            ( { newModel | page = FakeNotAuthorized }, Cmd.none )

        Just Route.RefreshAccountLink ->
            ( { newModel | page = RefreshAccountLink }
            , case Session.token newModel.taco.session of
                Just _ ->
                    intuitAuthorizationUri newModel.taco.session

                Nothing ->
                    Cmd.none
            )

        Nothing ->
            ( newModel, Cmd.none )



-- UPDATE


type Msg
    = NotificationMsg (Toasty.Msg Notification)
    | LinkClicked Browser.UrlRequest
    | UrlChanged Url.Url
    | RefreshToken
    | RefreshTokenResponse (GraphqlResult String)
    | UpdateTaco TacoUpdate
    | GotRefreshAccountLink (GraphqlResult String)
    | ToggleUserMenu
    | Logout
    | ProfileMsg Profile.Msg
    | TournamentsMsg Tournaments.Msg
    | ContactUsMsg ContactUs.Msg


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        NotificationMsg subMsg ->
            Notif.update NotificationMsg subMsg model

        LinkClicked urlRequest ->
            case urlRequest of
                Browser.Internal url ->
                    if
                        String.startsWith "/api" url.path
                            || Maybe.Extra.isJust url.fragment
                    then
                        ( model, Nav.load (Url.toString url) )

                    else
                        ( model
                        , Nav.pushUrl model.taco.navKey <|
                            Url.toString url
                        )

                Browser.External href ->
                    ( model, Nav.load href )

        UrlChanged url ->
            let
                ( newModel, cmd ) =
                    setPage url model
            in
            ( newModel, Cmd.batch [ cmd, Ports.postHogCapture "$pageview" ] )

        RefreshToken ->
            ( model
            , case model.taco.session of
                LoggedIn _ _ ->
                    Query.refreshToken
                        |> Api.query RefreshTokenResponse model.taco.session

                _ ->
                    Cmd.none
            )

        RefreshTokenResponse response ->
            case response of
                Ok token ->
                    let
                        ( newTaco, tacoCmd, notifs ) =
                            updateTaco
                                (SetSession <|
                                    case model.taco.session of
                                        LoggedIn _ userData ->
                                            LoggedIn token userData

                                        LoggingIn _ ->
                                            LoggingIn (Just token)

                                        Guest ->
                                            Guest
                                )
                                model.taco
                    in
                    ( { model | taco = newTaco }
                    , Cmd.map UpdateTaco tacoCmd
                    )
                        |> Notif.addMany NotificationMsg notifs

                Err error ->
                    ( model, Cmd.none )
                        |> Notif.add NotificationMsg (Notif.fromError error)

        UpdateTaco tacoUpdate ->
            let
                ( newTaco, tacoCmd, notifs ) =
                    updateTaco tacoUpdate model.taco

                newModel =
                    { model | taco = newTaco }
            in
            (case newModel.page of
                ProfilePage subModel ->
                    let
                        ( newSubModel, subCmd ) =
                            Profile.onTacoUpdate model.taco newTaco subModel
                    in
                    ( { newModel | page = ProfilePage newSubModel }
                    , Cmd.batch
                        [ Cmd.map UpdateTaco tacoCmd
                        , Cmd.map ProfileMsg subCmd
                        ]
                    )

                TournamentsPage subModel ->
                    let
                        ( newSubModel, subCmd ) =
                            Tournaments.onTacoUpdate model.taco newTaco subModel
                    in
                    ( { newModel | page = TournamentsPage newSubModel }
                    , Cmd.batch
                        [ Cmd.map UpdateTaco tacoCmd
                        , Cmd.map TournamentsMsg subCmd
                        ]
                    )

                RefreshAccountLink ->
                    ( newModel
                    , Cmd.batch
                        [ Cmd.map UpdateTaco tacoCmd
                        , case ( Session.token model.taco.session, Session.token newModel.taco.session ) of
                            ( Nothing, Just _ ) ->
                                intuitAuthorizationUri newModel.taco.session

                            _ ->
                                Cmd.none
                        ]
                    )

                _ ->
                    ( newModel, Cmd.map UpdateTaco tacoCmd )
            )
                |> Notif.addMany NotificationMsg notifs

        GotRefreshAccountLink (Ok link) ->
            ( model, Nav.load link )

        GotRefreshAccountLink (Err _) ->
            ( model, Cmd.none )
                |> Notif.add NotificationMsg (Notif.Error "Something went wrong.")

        ToggleUserMenu ->
            ( { model | showUserMenu = not model.showUserMenu }, Cmd.none )

        Logout ->
            let
                ( newTaco, tacoCmd, notifs ) =
                    updateTaco (SetSession Guest) model.taco
            in
            ( { model | taco = newTaco, showUserMenu = False }
            , Cmd.map UpdateTaco tacoCmd
            )
                |> Notif.addMany NotificationMsg notifs

        ProfileMsg subMsg ->
            case model.page of
                ProfilePage subModel ->
                    let
                        ur =
                            Profile.update model.taco subMsg subModel

                        ( newTaco, tacoCmd, notifs ) =
                            recursiveUpdateTaco ur.tacoUpdates model.taco
                    in
                    ( { model
                        | page = ProfilePage ur.model
                        , taco = newTaco
                      }
                    , Cmd.batch
                        [ Cmd.map ProfileMsg ur.cmd
                        , Cmd.map UpdateTaco tacoCmd
                        ]
                    )
                        |> Notif.addMany NotificationMsg (ur.notifications ++ notifs)

                _ ->
                    ( model, Cmd.none )

        TournamentsMsg subMsg ->
            case model.page of
                TournamentsPage subModel ->
                    let
                        ( newSubModel, subCmd, notifs ) =
                            Tournaments.update model.taco subMsg subModel
                    in
                    ( { model
                        | page = TournamentsPage newSubModel
                      }
                    , Cmd.batch
                        [ Cmd.map TournamentsMsg subCmd
                        ]
                    )
                        |> Notif.addMany NotificationMsg notifs

                _ ->
                    ( model, Cmd.none )

        ContactUsMsg subMsg ->
            case model.page of
                ContactUsPage subModel ->
                    let
                        ( newSubModel, cmd ) =
                            ContactUs.update model.taco.session subMsg subModel
                    in
                    ( { model | page = ContactUsPage newSubModel }
                    , Cmd.map ContactUsMsg cmd
                    )

                _ ->
                    ( model, Cmd.none )


intuitAuthorizationUri : Session -> Cmd Msg
intuitAuthorizationUri session =
    Query.intuitAuthorizationUri
        |> Api.query GotRefreshAccountLink session



-- SUBSCRIPTIONS


minute : Float
minute =
    60 * 1000


subscriptions : Model -> Sub Msg
subscriptions model =
    Sub.batch <|
        Time.every
            (minute
                - toFloat
                    (modBy
                        (round minute)
                        (Time.posixToMillis model.taco.now.time)
                    )
            )
            (UpdateTaco << SetTime)
            :: (Tournaments.subscriptions
                    |> Sub.map TournamentsMsg
               )
            :: (case model.taco.session of
                    LoggedIn _ _ ->
                        [ Time.every (60 * minute) (always RefreshToken) ]

                    _ ->
                        []
               )



-- VIEW


view : Model -> Browser.Document Msg
view { notifications, taco, showUserMenu, page } =
    let
        ( content, extraLinks ) =
            case page of
                HomePage ->
                    ( Home.view, [] )

                ProfilePage subModel ->
                    Profile.view taco subModel
                        |> Tuple.mapFirst (Html.map ProfileMsg)

                TournamentsPage subModel ->
                    Tournaments.view taco subModel
                        |> Tuple.mapFirst (Html.map TournamentsMsg)

                SupportPage subModel ->
                    Support.view subModel

                ContactUsPage subModel ->
                    ContactUs.view subModel
                        |> Tuple.mapFirst (Html.map ContactUsMsg)

                PrivacyPolicyPage ->
                    ( PrivacyPolicy.view, [] )

                FakeNotAuthorized ->
                    ( div [ class "tc" ] [ text "Not authorized until club approved." ], [] )

                RefreshAccountLink ->
                    ( text "", [] )
    in
    { title = "ClayRank"
    , body =
        [ div [ class "helvetica near-black min-vh-100 flex flex-column" ]
            [ nav [] <|
                [ div
                    [ class
                        "pa2 pa3-ns f6 f5-ns bb b--black-10 flex items-center"
                    ]
                    [ a
                        [ class "link dim mr3 b orange"
                        , Route.href Route.Home
                        ]
                        [ img [ src taco.logoUrl, alt "ClayRank" ] [] ]
                    , a
                        [ class "link near-black dim mr3"
                        , Route.href (Route.Support Route.HowTo)
                        ]
                        [ text "Support" ]
                    , a
                        [ class "link near-black dim mr3"
                        , Route.href Route.ContactUs
                        ]
                        [ text "Contact Us" ]
                    , a
                        [ class "link gray dim mr3"
                        , Route.href Route.PrivacyPolicy
                        ]
                        [ text "Privacy Policy" ]
                    , a
                        [ class "link gray dim"
                        , href "https://res.cloudinary.com/electric/image/upload/clayrank/end_user_license_agreement_jqltzg.pdf"
                        , target "_blank"
                        ]
                        [ text "EULA" ]
                    , div [ class "flex-auto" ] []
                    , userNav showUserMenu taco.session
                    ]
                ]
                    ++ (if List.isEmpty extraLinks then
                            []

                        else
                            [ div
                                [ class <|
                                    "ph2 ph3-ns pv2 pv3-ns bb b--black-10 "
                                        ++ "bg-near-white f7 f6-ns"
                                ]
                                (List.map
                                    (\( label, route ) ->
                                        a
                                            [ class "link dim gray dib mr3"
                                            , Route.href route
                                            ]
                                            [ text label ]
                                    )
                                    extraLinks
                                )
                            ]
                       )
            , main_ [ class "flex-auto ma4 mt3" ] [ content ]
            , Notif.view NotificationMsg notifications
            ]
        ]
    }


userNav : Bool -> Session -> Html Msg
userNav showUserMenu session =
    div [ class "dt h2" ]
        [ div [ class "dtc v-mid" ]
            [ case session of
                LoggedIn _ { user } ->
                    div [ class "relative" ] <|
                        [ span [ class "pointer", onClick ToggleUserMenu ]
                            [ avatar [] user
                            , span [ class "dtc h2 v-mid" ]
                                [ i [ class "ml1 fas fa-caret-down" ] []
                                ]
                            ]
                        ]
                            ++ (if showUserMenu then
                                    [ userMenu ]

                                else
                                    []
                               )

                LoggingIn _ ->
                    div [] []

                Guest ->
                    a
                        [ class "link gray dim"
                        , Route.href (Route.Profile Route.viewProfileDefault)
                        ]
                        [ text "Club Login" ]
            ]
        ]


userMenu : Html Msg
userMenu =
    div [] <|
        [ div
            [ class "fixed left-0 top-0 w-100 h-100"
            , onClick ToggleUserMenu
            ]
            []
        , div
            [ class
                "absolute top-2 right-0 mt2 bg-white ba b--black-10 tc z-1"
            ]
            [ a
                [ class "link db pv2 ph3 near-black dim"
                , Route.href (Route.Profile Route.viewProfileDefault)
                ]
                [ text "Profile" ]
            , div [ class "mh2 bb b--black-10" ] []
            , span
                [ class "link db pv2 ph3 near-black dim pointer"
                , onClick Logout
                ]
                [ text "Logout" ]
            ]
        ]
